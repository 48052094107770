var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"dashboard d-flex flex-wrap justify-center"},[_c('div',[_c('v-card',[_c('ul',[_c('li',[_vm._v("Nieuwe update: Probleem opgelost met leverdatum")])]),_c('v-card-title',{staticClass:"text-h4 justify-center"},[_vm._v(_vm._s(_vm.client.name))])],1),_c('v-card',[_c('v-card-title',{staticClass:"text-h4 justify-center"},[_vm._v("Openstaand Saldo")]),_c('v-card-text',{staticClass:"text-h5 text-center"},[_vm._v(_vm._s(_vm.toCurrency(_vm.debit)))])],1)],1),_c('div',[_c('v-card',[_c('v-card-title',{staticClass:"text-h4 justify-center"},[_vm._v("Nog te factureren")]),_c('v-card-text',{staticClass:"text-h5 text-center"},[_vm._v(_vm._s(_vm.toCurrency(_vm.openOrdersDebit)))])],1)],1),_c('div',[_c('v-card',[_c('v-data-table',{attrs:{"items":_vm.openOrders,"headers":_vm.openOrdersHeaders,"server-items-length":_vm.openOrdersLength,"options":_vm.openOrdersOptions,"footer-props":{
        showFirstLastPage: true,
        'items-per-page-options': [10, 25, 50, 100]
      },"item-key":"_id"},on:{"update:options":function($event){_vm.openOrdersOptions=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":_vm.orderStatuses[item.status].color}},[_vm._v(_vm._s(_vm.orderStatuses[item.status].text))])]}},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateString(item.date))+" ")]}},{key:"item.grossTotal",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.grossTotal))+" ")]}}],null,true)})],1)],1),_c('div')])],1)}
var staticRenderFns = []

export { render, staticRenderFns }